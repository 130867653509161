<template>
    <v-container grid-list-md>
        <v-row dense align="center" ma-2>
            <v-col cols="4">
                <v-card>
                    <v-card-title class="primary white--text subtitle">ASISTENCIA</v-card-title>
                    <v-card-text>
                        <form @submit.prevent="postAsistencia">
                            <v-row dense align="center">
                                <v-col cols="12" class="pt-3">
                                    <v-autocomplete outlined dense v-model="documento" item-text="name"
                                        v-validate="'required'" required item-value="documento" :items="_listaColaborador"
                                        label="Colaboradores" clearable />

                                </v-col>
                                <v-radio-group v-model="idTipoRegistro">
                                    <v-radio v-for="n in itemsRegistro" :key="n.id" :label="n.text" :value="n.id"></v-radio>
                                </v-radio-group>
                                <v-col cols="12" class="py-2">
                                    <v-btn color="primary" :loading="loading" dark type="submit" block>MARCAR
                                        ASISTENCIA</v-btn>
                                </v-col>
                            </v-row>
                        </form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="8" class="white">
                <v-row dense>
                    <v-col cols="12" class="pa-2">
                        <b>ASISTENCIA DEL DÍA</b>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-card min-height="200">
                            <v-card-text>
                                <v-list>
                                    <div>
                                        <v-data-table :headers="registro" :items="RegistroColab" class="elevation-1">
                                            <template v-slot:[`item.deleteIngreso`]="{ item }">
                                                <v-icon color="primary" small
                                                    @click="borrarRegistro(item, 1)">mdi-delete</v-icon>
                                            </template>
                                            <template v-slot:[`item.deleteSalida`]="{ item }">
                                                <v-icon color="primary" small
                                                    @click="borrarRegistro(item, 2)">mdi-delete</v-icon>
                                            </template>
                                        </v-data-table>
                                    </div>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row dense align="center" ma-2>
            <v-col cols="4">
                <v-card>
                    <v-card-title class="primary white--text subtitle">REPORTES</v-card-title>
                    <v-card-text class="d-flex justify-center pa-2">
                        <form @submit.prevent="downloadReport">
                            <v-row justify="center">
                                <v-col cols="12" sm="6" md="6">
                                    <v-menu ref="menu5" v-model="menu5" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="firstDay" label="Fecha Inicio"
                                                prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="firstDay" no-title scrollable @input="menu5 = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-menu ref="menu6" v-model="menu6" :close-on-content-click="false"
                                        transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="nextDay" label="Fecha Fin" prepend-icon="mdi-calendar"
                                                readonly v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="nextDay" no-title scrollable @input="menu6 = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="7">
                                    <v-autocomplete outlined dense v-model="reportCollaborator" item-text="name"
                                        v-validate="'required'" required item-value="id" :items="_listaColaborador"
                                        label="Colaboradores" return-object />
                                </v-col>
                                <v-col v-if="![4, 6].includes(user.id_role)" cols="5" class="d-flex justify-center">
                                    <v-btn color="primary" @click="getListaReporte" :loading="loadingReport"> BUSCAR</v-btn>
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col v-if="![4, 6].includes(user.id_role)" cols="12" class="d-flex justify-center">
                                    <v-btn color="blue" dark block type="submit"> BOLETA</v-btn>
                                </v-col>
                                <v-col>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" class="d-flex justify-center  ">
                                    <v-btn block color="green" dark @click="openReporteActividades()">AGREGAR REPORTE DE
                                        ACTIVIDADES</v-btn>
                                </v-col>
                            </v-row>
                        </form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="8" class="white">
                <v-row dense>
                    <v-col cols="8" class="pa-2">
                        <b>REPORTES</b>
                    </v-col>
                    <v-col cols="4" class="pa-2">
                        <v-btn color="primary" @click="descargarArchivo">DESCARGAR WORD</v-btn>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-data-table multi-sort dense :headers="headers" :items="_listaReporte" :items-per-page="10"
                            class="elevation-1">
                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon small v-bind="attrs" v-on="on" @click="editItem(item)">
                                            <v-icon small color="green lighten-2">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon small v-bind="attrs" v-on="on" @click="deleteItem(item)">
                                            <v-icon color="primary" small>mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Eliminar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog v-model="openModal" max-width="290" transition="dialog-bottom-transition">
            <v-card outlined>
                <v-card-text>
                    <b>{{ colaborador }}</b>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" dark @click="openModal = false">OK</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="500" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline">REPORTE DE ACTIVIDADES</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form ref="form" class="pa-4">
                        <v-autocomplete :rules="[(v) => !!v || 'Colaborador es requerido']" dense
                            v-model="activity.idColaborador" item-text="name" item-value="id" :items="_listaColaborador"
                            label="Colaborador" />
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field :rules="[(v) => !!v || 'Dia es requerido']" v-model="activity.date"
                                    label="Dia" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="activity.date" @input="menu2 = false"></v-date-picker>
                        </v-menu>
                        <v-text-field :rules="hourRules" v-model="activity.hourEntry" @input="formatHour('hourEntry')"
                            prepend-icon="mdi-clock-time-four-outline" label="Hora de Ingreso" placeholder="HH:mm"
                            required></v-text-field>
                        <v-text-field :rules="hourRules" v-model="activity.hourOutput" label="Hora de Salida"
                            placeholder="14:00" prepend-icon="mdi-clock-time-four-outline" required
                            @input="formatHour('hourOutput')"></v-text-field>
                        <v-time-picker v-if="menu3" v-model="activity.hourOutput" full-width
                            @click:minute="$refs.menu3.save(activity.hourOutput)"></v-time-picker>
                        <v-text-field v-model="activity.numTicket" label="Ticket" placeholder="ticket"></v-text-field>
                        <v-text-field v-model="activity.numOt" label="Orden Trabajo" placeholder="ot"></v-text-field>

                        <v-col cols="12" justify-center class="blue lighten-5">
                            <h5>REPORTE</h5>

                            <v-row>
                                <v-col cols="6">
                                    <v-select clearable v-model="itemForm.status" :items="listStatus" label="Estado">
                                    </v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="itemForm.quantity" label="Cantidad"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="itemForm.activity" label="Actividad"></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn @click="agregarItem" color="green" dark>Agregar</v-btn>
                                </v-col>
                            </v-row>

                        </v-col>


                        <!-- Lista de items agregados -->
                        <v-list>
                            <v-list-item-group>
                                <v-list-item v-for="(item, index) in items" :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.status }} - {{ item.quantity }} - {{ item.activity
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn @click="eliminarItem(index)" icon>
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-form>
                </v-card-text>
                <v-card-actions class="grey lighten-4">
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="closeActivity">Cancelar</v-btn>
                    <v-btn color="primary" @click="reportActivity">{{ typeResult == 1 ? 'Guardar' : 'Editar' }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { addMonths, format, parseISO, subMonths, differenceInMinutes, parse, addMinutes, formatDuration, add, zonedTimeToUtc } from 'date-fns'
const { es } = require('date-fns/locale')
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import { exportXLSX } from '@/utils'

export default {
    filters: {
        getHora(val) {
            if (val) {
                return format(parseISO(val.replace('.000Z', '')), 'hh:mm a')
            } else {
                return null
            }
        }
    },
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            items: [],
            itemForm: {
                status: "",

            }, hourRules: [
                (v) => !!v || 'Hora es requerido',
                (v) => /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Formato de hora inválido'
            ],
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'Colaborador', value: 'colaborador' },
                { text: 'dia', value: 'dia' },
                { text: 'Hora Ingreso', value: 'horarioIngreso' },
                { text: 'Hora Salida', value: 'horaSalida' },
                { text: 'Actividades Nuevo', value: '_jsActividades' },


                { text: 'Actividades Antiguo', value: 'actividades_label' }
            ],
            reportCollaborator: null,
            modal: false,
            date: new Date().toISOString().substr(0, 7),
            idTipoRegistro: 1, //2 salida
            openModal: false,
            toggle_multiple: [],
            itemsRegistro: [
                { id: 1, text: 'Hora de ingreso' },
                { id: 3, text: 'Inicio del Refrigerio' },
                { id: 4, text: 'Fin del Refrigerio' },
                { id: 2, text: 'Hora de Salida' }
            ],
            typeResult: 1,
            nextDay: null,
            firstDay: null,
            menu2: false,
            menu1: false,
            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false,
            activity: {
                date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10), hourEntry: '',
                hourExit: ''
            },
            loadingReport: false,
            dialog: false,
            documento: null,
            loading: false,
            colaborador: null,
            loadingDelete: false,
            listStatus: ["INICIO", "AVANCE", "TERMINADO"],
            registro: [
                { text: 'Colaborador', value: 'full_name' },
                { text: 'Hora de Llegada', value: 'ingreso' },
                { text: 'Hora del Refrigerio', value: 'AlmuerzoIngreso' },
                { text: 'Fin del Refrigerio)', value: 'AlmuerzoSalida' },
                { text: 'Hora de Salida', value: 'salida' },
                { text: 'Borrar Ingreso', value: 'deleteIngreso', sortable: false },
                { text: 'Borrar Salida', value: 'deleteSalida', sortable: false }
            ]
        }
    },
    computed: {
        ...mapState('users', ['user', 'listaUser']),
        ...mapGetters('asistencia', ['listaIngreso', 'listaEgreso', 'listaInicioRefrigerio', 'listaFinRefrigerio']),
        ...mapState('asistencia', ['listaAsistencia', 'listaColaborador', 'listaReporte']),
        _listaColaborador() {
            let items = []
            this.listaColaborador.map((x) =>
                items.push({
                    ...x,
                    name: `${x.nombres} , ${x.apellidos}`.toUpperCase()
                })
            )
            return items
        },
        RegistroColab() {
            return this.listaAsistencia
        },
        _listaReporte() {
            const isAuthorizedRole = [2, 3, 4].includes(this.user.id_role)
            let data = this.listaReporte.map((x) => ({
                ...x,
                colaborador: this.listaColaborador.find((y) => y.id == x.idColaborador)?.nombres,
                actividades_label: x.actividades ? x.actividades.substring(0, 10).concat('...') : "",
                _jsActividades: JSON.stringify(x.jsActividades),
                jsActividades: x.jsActividades
            }))
            if (!isAuthorizedRole) {
                data = data.filter((x) => x.idColaborador == this.user.id)
            }
            console.log('data-----', data)
            return data
        },
        report() {
            return {
                dataSource: {
                    data: [
                        { id: 1, Currency: 'dollars', Amount: 'aaa', Available: true },
                        { id: 2, Currency: 'euros', Amount: 'bbb', Available: false }
                    ]
                }
            }
        }
    },
    async mounted() {
        this.nextDay = format(addMonths(new Date(), 1), 'yyyy-MM-dd')
        this.firstDay = format(subMonths(new Date(), 1), 'yyyy-MM-dd')
        await Promise.all([this.getLista(), this.getListaColanorador(), this.getListaReporte()])
    },
    methods: {
        descargarArchivo() {
            // Lógica para descargar el archivo de texto
            const contenidoArchivo = this.generarContenidoArchivo();

            // Crear un Blob con el contenido
            const blob = new Blob([contenidoArchivo], { type: 'text/plain' });

            // Crear un objeto URL para el Blob
            const url = window.URL.createObjectURL(blob);

            // Crear un elemento <a> para simular el clic y la descarga del archivo
            const a = document.createElement('a');
            a.href = url;
            const date = format(new Date(), 'dd/MM/yyyy')

            a.download = 'ACTIVIDADES REPORTADAS EL DIA ' + date + '.txt';

            // Agregar el elemento <a> al DOM y simular el clic
            document.body.appendChild(a);
            a.click();

            // Limpiar el elemento <a> y liberar el objeto URL
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        },
        generarContenidoArchivo() {
            // Lógica para generar el contenido del archivo de texto
            let contenido = ''
            console.log("this._listaReporte", this._listaReporte)
            this._listaReporte.map(z => {
                const filtrado = this.listaColaborador.find((y) => y.id == z.idColaborador)?.nombres

                contenido += `COLABORADOR: ${filtrado}\n`;
                contenido += `TICKET: ${z.numTicket || ''}\n`;
                contenido += `OT: ${z.numOt || ''}\n`;
                if (z.jsActividades) {
                    contenido += `ESTADO -- CANTIDAD -- ACTIVIDAD \n`;
                    z.jsActividades.map(y => {
                        contenido += ` ${y.status || ''} -- ${y.quantity || ''} -- ${y.activity || ''} \n`;
                    })
                } else {
                    contenido += `${z.actividades || ''} \n`;
                }

                contenido += `_______________________________ \n`;
            })



            return contenido;
        },
        formatHour(field) {
            // Formatea automáticamente la hora al formato deseado
            if (this.activity[field].length === 2 && !this.activity[field].includes(':')) {
                this.activity[field] += ':';
            }
        },
        eliminarItem(index) {
            // Eliminar el item en el índice dado
            this.items.splice(index, 1);
        },
        agregarItem() {
            // Hacer una copia del objeto para evitar referencia directa
            const nuevoItemCopia = Object.assign({}, this.itemForm);

            // Agregar el nuevo item al array
            this.items.push(nuevoItemCopia);

            // Limpiar los campos
            this.itemForm.status = '';
            this.itemForm.quantity = '';
            this.itemForm.activity = '';
        },
        async getLista() {
            await this.$store.dispatch('asistencia/getLista', {
                idSede: this.user.idSede,
                tipo: 1,
                fecha: format(new Date(), 'yyyy-MM-dd')
            })
        },
        getInfo(val) {
            const filtrado = this.listaAsistencia.filter((x) => x.idColaborador === val)
            const itemIngreso = filtrado.filter((x) => x.idTipoRegistro == 1)
            const itemEgreso = filtrado.filter((x) => x.idTipoRegistro == 2)
            const itemInicioRefrigerio = filtrado.filter((x) => x.idTipoRegistro == 3)
            const itemFinRefrigerio = filtrado.filter((x) => x.idTipoRegistro == 4)
            return {
                fechaingreso: itemIngreso.length > 0 ? itemIngreso[0].created_at : null,
                fechaegreso: itemEgreso.length > 0 ? itemEgreso[0].created_at : null,
                fechaInicioRefrigerio: itemInicioRefrigerio.length > 0 ? itemInicioRefrigerio[0].created_at : null,
                fechaFinRefrigerio: itemFinRefrigerio.length > 0 ? itemFinRefrigerio[0].created_at : null,
                full_name: filtrado.length > 0 ? filtrado[0].full_name : null
            }
        },
        async reportActivity() {
            if (this.$refs.form.validate()) {
                // Realiza una solicitud a tu API para guardar los datos
                console.log(' this.activity', this.activity)
                if (this.typeResult == 1) {
                    console.log('crear')
                    await this.$store.dispatch('asistencia/postReporte', { ...this.activity, jsActividades: this.items })
                } else {
                    await this.$store.dispatch('asistencia/updateReporte', { ...this.activity, jsActividades: this.items })
                }
                await this.getListaReporte()
                this.itemForm = {}
                // this.closeActivity()
                this.activity = {}
                this.dialog = false
            }
        },
        openReporteActividades() {
            this.typeResult = 1
            this.items = []
            this.dialog = true
        },
        async downloadReport() {
            const reporteDiario = []
            let diasLaborados
            let notas = []
            let sumaHorasPendientes = '00:00'
            let sumaHorasExtra = '00:00'
            const formatTime = (time) => parse(time, 'H:mm', new Date())
            console.log("_listaReporte",this._listaReporte)
            this._listaReporte.map((x, i) => {
                const dateUnique = format(parseISO(x.dia), 'EEEE', { locale: es })
                const horarioEntrada = differenceInMinutes(formatTime(x.horarioIngreso), formatTime('08:00'))
                const horarioSalida =
                    dateUnique == 'sábado'
                        ? differenceInMinutes(formatTime(x.horaSalida), formatTime('13:00'))
                        : differenceInMinutes(formatTime(x.horaSalida), formatTime('18:00'))
                let horaPendiente = 0
                let horaExtra = 0
                horarioEntrada < 0 ? (horaExtra += horarioEntrada * -1) : (horaPendiente += horarioEntrada)
                horarioSalida < 0 ? (horaPendiente += horarioSalida * -1) : (horaExtra += horarioSalida)
                reporteDiario.push({
                    fecha: x.dia,
                    dia: dateUnique,
                    horaIngreso: x.horarioIngreso,
                    horaPendiente: format(new Date(0, 0, 0, 0, horaPendiente), 'H:mm'),
                    horaSalida: x.horaSalida,
                    horaExtra: format(new Date(0, 0, 0, 0, horaExtra), 'H:mm')
                })
                diasLaborados = i + 1
                notas.push({
                    dia: x.dia,
                    descripcion: x.jsActividades.map(x=>x.activity).join(" | ")
                })
            })
            reporteDiario.map((x) => {
                const [h1, m1] = x.horaPendiente.split(':').map(Number)
                const [h2, m2] = sumaHorasPendientes.split(':').map(Number)
                const [h3, m3] = x.horaExtra.split(':').map(Number)
                const [h4, m4] = sumaHorasExtra.split(':').map(Number)
                const totalMinutos = h1 * 60 + m1 + h2 * 60 + m2
                const totalMinutosExtra = h3 * 60 + m3 + h4 * 60 + m4
                const horas = Math.floor(totalMinutos / 60)
                const horasExtra = Math.floor(totalMinutosExtra / 60)
                const minutos = totalMinutos % 60
                const minutosExtra = totalMinutosExtra % 60
                sumaHorasPendientes = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`
                sumaHorasExtra = `${horasExtra.toString().padStart(2, '0')}:${minutosExtra.toString().padStart(2, '0')}`
            })
            const { sueldo } = this.reportCollaborator
            const sueldoMinuto = sueldo / (60 * 24 * 30)
            const [horasExtra2, minutosExtra2] = sumaHorasExtra.split(':')
            const [horasPendiente2, minutosPendiente2] = sumaHorasPendientes.split(':')
            const ingresoBonus = Number(((parseInt(horasExtra2) * 60 + parseInt(minutosExtra2)) * sueldoMinuto).toFixed(2))
            const totalDsct = Number(((parseInt(horasPendiente2) * 60 + parseInt(minutosPendiente2)) * sueldoMinuto).toFixed(2))
            const data = {
                reporteDiario,
                diasLaborados,
                horasExtras: sumaHorasExtra,
                horasPendiente: sumaHorasPendientes,
                remuneracionDiaria: (Number(sueldo) / 30).toFixed(2),
                totalRemuneracion: (Number(sueldo) / 30).toFixed(2) * diasLaborados,
                fecha: `Del ${this.firstDay} al ${this.nextDay}`,
                sueldoAcordado: Number(sueldo),
                nombres: this.reportCollaborator.name,
                documento: this.reportCollaborator.documento,
                descuento: `${sumaHorasPendientes} minutos`,
                minutos: ` ${sueldoMinuto.toFixed(2)}  `,
                totalDsct,
                netoPagar: ((Number(sueldo) / 30) * diasLaborados + ingresoBonus - totalDsct).toFixed(2),
                ingresosTrabajador: {
                    salarioSemanal: ((Number(sueldo) / 30) * 7).toFixed(2),
                    horasExtras: sumaHorasExtra,
                    dsct: `S/ ${ingresoBonus}`,
                    totalRemuneracionBrutal: ((Number(sueldo) / 30) * diasLaborados + ingresoBonus - totalDsct).toFixed(2)
                },
                notas
            }
            await this.$store.dispatch('asistencia/downloadReporte', data)
        },

        async chooseDate() {
            this.$refs.menu4.save(this.date)
            await this.getListaReporte()
        },
        closeActivity() {
            this.dialog = false
            this.$refs.form.reset()
        },
        editItem(item) {
            console.log('itemxxx', item)
            this.typeResult = 2
            const { actividades, dia, horaSalida, horarioIngreso, idColaborador, id, numTicket, numOt, jsActividades } = item
            console.log("ITEMSxxx", actividades)
            this.activity = {
                idColaborador,
                id,
                date: dia,
                hourEntry: horarioIngreso,
                hourOutput: horaSalida,
                text: actividades,
                jsActividades: jsActividades,
                numTicket, numOt
            }
            this.items = jsActividades
            this.dialog = true
        },
        async deleteItem(item) {
            console.log('item', item)
            await this.$store.dispatch('asistencia/deleteReporte', {
                id: item.id
            })
            await this.getListaReporte()
        },
        async postAsistencia() {
            const filterUser = this._listaColaborador.find((x) => x.documento == this.documento)
            const findAsistenciaIngreso = await this.$store.dispatch('asistencia/findAsistencia', {
                idColaborador: filterUser.id,
                fecha: format(new Date(), 'yyyy-MM-dd')
            })
            let satisfactory = false
            const filterIdColab = this.listaAsistencia.find((x) => x.idColaborador == filterUser?.id)
            if (filterIdColab) {
                switch (this.idTipoRegistro) {
                    case 1:
                        if (filterIdColab.ingreso) {
                            satisfactory = true
                            this.openModal = true
                            this.colaborador = `El colaborador ya registro su hora de ingreso`
                        }
                        break
                    case 2:
                        if (filterIdColab.salida) {
                            satisfactory = true
                            this.openModal = true
                            this.colaborador = `El colaborador ya registro su horario de salida`
                        }
                        break
                    case 3:
                        if (filterIdColab.AlmuerzoIngreso) {
                            this.openModal = true
                            satisfactory = true
                            this.colaborador = `El colaborador ya empezo su hora de refrigerio`
                        }
                        break
                    case 4:
                        if (filterIdColab.AlmuerzoSalida) {
                            satisfactory = true
                            this.openModal = true
                            this.colaborador = `El colaborador ya culmino su hora de regrigerio`
                        }
                        break
                }
                if (satisfactory) {
                    this.openModal = true
                    this.colaborador = `El colaborador ya se encuentra registrado`
                } else {
                    if (findAsistenciaIngreso && this.idTipoRegistro == 2) {
                        this.openModal = true
                        this.colaborador = `El colaborador seleccionado no tiene una hora de ingreso`
                    } else if (findAsistenciaIngreso && this.idTipoRegistro == 3) {
                        this.openModal = true
                        this.colaborador = `El Colaborador aún no se encuentra en el trabajo`
                    } else if (findAsistenciaIngreso && this.idTipoRegistro == 4) {
                        this.openModal = true
                        this.colaborador = `El Colaborador Seleccionado aún no dio inicio a su refrigerio`
                    } else {
                        this.loading = true
                        const isUser = await this.$store.dispatch('asistencia/postLista', {
                            numeroDocumento: this.documento,
                            idTipoRegistro: this.idTipoRegistro
                        })
                        if (isUser) {
                            this.openModal = true
                            this.colaborador = `Se agregó exitosamente - ${isUser.nombres}`
                        } else {
                            this.openModal = true
                            this.colaborador = 'COLABORADOR NO EXISTE'
                        }
                        this.documento = null
                        await this.getLista()

                        this.loading = false
                    }
                }
            } else {
                this.loading = true
                const isUser = await this.$store.dispatch('asistencia/postLista', {
                    numeroDocumento: this.documento,
                    idTipoRegistro: this.idTipoRegistro
                })
                if (isUser) {
                    this.openModal = true
                    this.colaborador = `Se agregó exitosamente - ${isUser.nombres}`
                } else {
                    this.openModal = true
                    this.colaborador = 'COLABORADOR NO EXISTE'
                }
                this.documento = null
                await this.getLista()
                this.loading = false
            }
        },
        async getListaColanorador() {
            await this.$store.dispatch('asistencia/getListaColaborador')
        },
        async getLista() {
            await this.$store.dispatch('asistencia/getLista', {
                idSede: this.user.idSede,
                tipo: 1,
                fecha: format(new Date(), 'yyyy-MM-dd')
            })
        },
        async getListaReporte() {
            this.loadingReport = true
            const colab = this.listaUser.filter((x) => {
                return x.idSede == this.user.idSede
            })
            await this.$store.dispatch('asistencia/getListaReporte', {
                firstDay: this.firstDay,
                nextDay: this.nextDay,
                idColaborador: this.reportCollaborator?.id,
                colab,
                id_role: this.user.id_role
            })
            this.loadingReport = false
        },

        async borrarRegistro(data, numero) {
            if (numero == 1) {
                this.loadingDelete = true
                await this.$store.dispatch('asistencia/borrarRegistro', {
                    iddata: data.idEntrada
                })
                this.loadingDelete = false
            } else if (numero == 2) {
                await this.$store.dispatch('asistencia/borrarRegistro', {
                    iddata: data.idSalida
                })
            }
            this.documento = null
            await this.getLista()
            this.loading = false
        }
    }
}
</script>
